import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import loadable from 'react-loadable';
import LoadingComponent from 'components/Loading';
import Cookies from 'universal-cookie';
import NoonReportForm from '../routes/vessel-activate-list/Noon-report-form';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// 3rd
import 'styles/antd.less';
import 'styles/bootstrap/bootstrap.scss'
// custom
import "styles/layout.scss"
import "styles/theme.scss"
import "styles/ui.scss"
import "styles/vendors.scss"
import "styles/custom.scss";
import "styles/context-menu.scss";



let AsyncAppLayout = loadable({
  loader: () => import('components/Layout/AppLayout'),
  loading: LoadingComponent
})
let AsyncException = loadable({
  loader: () => import('routes/exception/'),
  loading: LoadingComponent
})
let AsyncAccount = loadable({
  loader: () => import('routes/user/'),
  loading: LoadingComponent
})




let AsyncSharedLink = loadable({
  loader: () => import('routes/docs/'),
  loading: LoadingComponent
}) // Public route



let NoonForm = loadable({
  loader: () => import('../routes/noonform/Noonform'),
  loading: LoadingComponent
}) 

const cookies = new Cookies();
class App extends React.Component {
  render() {
    let username=cookies.get('username')? cookies.get('username') : null
    let user_type=cookies.get('user_type')? cookies.get('user_type') : null

    const { match, location } = this.props;
    const isRoot = location.pathname === '/' ? true : false;
    // if(isRoot && username && user_type){
    //   return ( <Redirect to={'#/dashboard'}/> );
    // }
    // if(username==null && user_type==null){
    //   return ( <Redirect to={'/user/login'}/> );
    // }
     if (isRoot && username == null && user_type == null) {
      return ( <Redirect to={'/user/login'}/> );
    }if(location.pathname!=="/user/login" && username == null && user_type == null){
      
      return ( <Redirect to={'/'}/> );
    }
  
    return (
      <div id="app">
        <Switch> 
          <Route path={`${match.url}exception`} component={AsyncException} />
          <Route path={`${match.url}user`} component={AsyncAccount} />
          <Route path={`${match.url}docs`} component={AsyncSharedLink} />
          <Route path={`${match.url}report`} component={NoonForm} />
          <Route path={`${match.url}`} component={AsyncAppLayout} />
         </Switch> 
      </div>
    );
  }
}

export default App;
