import React, { Component } from "react";
import { render } from "react-dom";
import configureStore, { history } from "./store/configureStore";
import Root from "./components/Root";
import * as serviceWorker from "./serviceWorker";
import { func } from "prop-types";
import { Form } from "antd";
import { keys } from "lodash";
const store = configureStore();

render(
  <Root store={store} history={history} />,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

const storeLogs = [];
const logKeys = {};

window.logTracker = (obj, row) => {
  FormSubmitTracker();
  obj["popup"] = window.getModelTittle();
  obj["name"] = obj.name + "@" + obj.popup;
  if (obj.name in logKeys) {
    storeLogs[logKeys[obj.name]].currentValue = obj.currentValue;
  } else {
    logKeys[obj.name] = storeLogs.length;
    obj.chnageBy = {
      userName: getCookie("username"),
      userId: getCookie("user_id"),
      userType: getCookie("user_type"),
      pass: getCookie("encpass"),
    };
    obj.userName = getCookie("username");
    obj.timeStamp = new Date();
    obj.VMID = getVmID();
    storeLogs.push(obj);
  }
  // console.table(storeLogs)
};
var IsClicked = false;

const OnSubmitFormHandler = () => {
  // console.log("submited the from With ")
  // console.log("Chnged Deilds are ..")
  // console.log(getLogsFeildsofPopUp())
  // alert("updating logs")
  submitToLogsServer();
  IsClicked = true;
};

const loginterCepter = () => {
  if (IsClicked) {
    IsClicked = false;
  }
  submitToLogsServer();
};

const submitToLogsServer = async () => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ logs: storeLogs, keys: logKeys }),
  };
  // alert("sengin to the server ")
  const response = await fetch(
    `${process.env.REACT_APP_VM_LOG}/api/saveVmlogs`,
    requestOptions
  );
  const data = await response.json();
  for (let i = 0; i < storeLogs.length; i++) {
    storeLogs.pop();
  }
  Object.keys(logKeys).forEach((key) => {
    delete logKeys[key];
  });
};

const getLogsFeildsofPopUp = () => {
  let title = window.getModelTittle();
  let logs = [];
  for (let log of storeLogs) {
    if (log.popup == title) {
      logs.push(log);
    }
  }
  return logs;
};

window.getModelTittle = () => {
  let modelCollection = document.getElementsByClassName("ant-modal-title");
  // console.log("model Collection ",modelCollection)
  if (modelCollection.length > 0) {
    return modelCollection[modelCollection.length - 1].textContent;
  }
  return "";
};

window.fetchRowName = (record, row) => {
  if ("port_id" in record) {
    return row.name + "@" + record.port_id;
  }
  if ("cargo_contract_id" in record) {
    return row.name + "@" + record.cargo_contract_id;
  }
  return row.name;
};

window.AbstractInputLogTracker = (row, evt, value, record) => {
  try {
    let obj = {
      name: window.fetchRowName(record, row),
      previousValue: value,
      currentValue: evt.target.value,
      type: "text",
      groupName: row.group_name,
    };
    window.logTracker(obj);
  } catch (e) {
    // console.log("intput Error" ,  e)
  }
};

window.corrector = (inputString) => {
  const partArray = inputString.split("||");
  let initialStr = "";

  if (Array.isArray(partArray[0]) && partArray[0].length > 0) {
    partArray[0][0] =
      partArray[0][0].charAt(0).toUpperCase() + partArray[0][0].slice(1);
    initialStr = partArray[0].join("") + "Invoice";
  } else if (typeof partArray[0] === "string" && partArray[0].trim() !== "") {
    partArray[0] = partArray[0].replace(/_/g, " ");
    partArray[0] = partArray[0].replace(/\b\w/g, (firstLetter) =>
      firstLetter.toUpperCase()
    );

    if (/\w\w+/.test(partArray[0])) {
      initialStr = partArray[0].replace(/([a-z])([A-Z])/g, "$1 $2") + " ";
    } else {
      initialStr = partArray[0] + " ";
    }
  }

  partArray.slice(1).forEach((item) => {
    if (item !== "undefined") {
      item = item.replace(/_/g, " ");
      item = item.replace(/\b\w/g, (firstLetter) => firstLetter.toUpperCase());
      initialStr = initialStr + ` || ${item}`;
    }
  });

  return initialStr;
};

window.AbstractDateLogtracker = (row, evt, value, record) => {
  // console.log(evt)
  try {
    let obj = {
      name: window.fetchRowName(record, row),
      previousValue: value._i,
      currentValue: evt._i,
      type: "date",
      groupName: row.group_name,
    };
    window.logTracker(obj);
  } catch (e) {
    // console.log("intput Error" ,  e)
  }
};

// 'username=theoceann; user_type=1; user_id=USER-00011; encpass=dGVzdEAxMjM%3D'

function getCookie(cookieName) {
  const cookies = document.cookie.split("; ");
  for (const cookie of cookies) {
    const [name, value] = cookie.split("=");
    if (name === cookieName) {
      return value;
    }
  }
  return null;
}

function getVmID() {
  const url = window.location.href;

  // Regular expression to extract text after '/voyage-manager/'
  const regex = /\/voyage-manager\/(.*)/;
  const match = url.match(regex);

  // Extracted text
  const extractedText = match ? match[1] : null;

  // console.log(extractedText);
  return extractedText;
}

const FormSubmitTracker = () => {
  var elements = document.getElementsByClassName("anticon anticon-save");
  // console.log("save elements ",elements)

  // Loop through each element
  for (let i = 0; i < elements.length; i++) {
    // Add click event listener to each element
    // console.log("submit form handler")

    elements[i].addEventListener("click", OnSubmitFormHandler);
  }
};

//    NETWORK INTERCEPTOR FOR THIS TAB WITH ALL MONITERLOGS

// Override the XMLHttpRequest object
const originalXHR = window.XMLHttpRequest;

function newXHR() {
  const xhr = new originalXHR();

  // Override the open method to log information about the XHR request
  const originalOpen = xhr.open;
  xhr.open = function(method, url, async, user, password) {
    // console.log('XHR Request:', url);
    // console.log('Method1:', method);
    return originalOpen.call(this, method, url, async, user, password);
  };

  // Override the onreadystatechange event to log when the request completes
  xhr.onreadystatechange = function() {
    if (xhr.readyState === 4) {
      loginterCepter();
      // console.log('XHR Respo  nse Status:', xhr.status);
      // console.log('XHR Response Text:', xhr.responseText);
    }
  };
  return xhr;
}

window.XMLHttpRequest = newXHR;
