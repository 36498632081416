import React, { useEffect, useState, useRef } from "react";
import "./noonReport.css";
import dayjs from "dayjs";
import { Input, Row, Col, Select, DatePicker, Button, Modal } from "antd";
import URL_WITH_VERSION, { getAPICall, openNotificationWithIcon, postAPICall } from "../../shared";
import PortSelection from "../port-selection/PortSelection";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { PrinterFilled, DownloadOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import Email from "../../components/Email";
import _ from "lodash";

const { Option } = Select;



const NoonReport = (props) => {
  const [companyDetails, setCompanyDetails] = useState({ company_name: "", company_logo: "", company_address: "" })
  const componentRef = useRef(null);
  const [seaState, setSeaState] = useState([])
  const [formdata, setFormdata] = useState({
    report_content: {
      mainInformation: {
        noon_vessel: "",
        noon_imo: "",
        noon_call_sign: "",
        noon_time_zone: "",
        noon_time_zone_plus: "",
        noon_9h: "",
        noon_voyage_no: "",
        noon_dateee: "",
      },
      portInformation: {
        noon_port: "",
        noon_port_id: "",
        noon_next_port: "",
        noon_next_port_id: "",
        noon_eta: "",
        noon_dtg: "",
        noon_hours_to_go: "",
        noon_lat: "",
        noon_lat_dir: "",
        noon_main_long: "",
        noon_main_long_dir: "",
      },
      cargoInformation: {
        noon_ttl_cargo_onboard: "",
        noon_displacement_mt: "",
        noon_deck_cargo_mt: "",
        noon_hold_cargo_mt: "",
        noon_fw_consumed: "",
        noon_fw_rob: "",
        noon_slop_rob: "",
        noon_slop_produced: "",
        noon_ballast_water: "",
        noon_distilled_cons: "",
        noon_distilled_rob: "",
        load_condition: "",
      },
      vesselDistance: {
        noon_passage: "",
        noon_ordered_spd: "",
        noon_reported_spd: "",
        noon_average_speed: "",
        noon_miles_steamed_since_last_noon_report: "",
        noon_engine_dist: "",
        noon_main_eng_rev: "",
        noon_observe_dist: "",
        noon_salinity: "",
        noon_density: "",
        noon_total_steaming_hr: "",
        noon_engine_oil_consumption: "",
        noon_incirator_hrs: "",
        noon_steaming_hr_at_slow_speed: "",
        noon_aver_rpm: "",
        noon_ave_bhp: "",
        noon_fwd_dft: "",
        noon_aft_drft: "",
        noon_steaming_hr: "",
        noon_air_press: "",
        noon_air_tempt: "",
        noon_sea_surface_temp: "",
        noon_total_miles_covered: "",
        noon_me_exhaust_gas_temp: "",
        noon_boiler_hrs: "",
        noon_incinertor_consumption: "",
        noon_slip_perc: "",
        noon_mid_draft: "",
        noon_gen_one_hr: "",
        noon_gen_two_hr: "",
        noon_gen_three_hr: "",
        noon_gen_four_hr: "",
        noon_me_hr: "",
        noon_gen_one_kw_hr: "",
        noon_gen_two_kw_hr: "",
        noon_gen_three_kw_hr: "",
        noon_gen_four_kw_hr: "",
        noon_ae_exhaust_gas_temp: "",
        noon_shaft_gen_running_hr: "",
        noon_steaming_hr_at_cp_speed: "",
        noon_main_engine_rpm: "",
        noon_me_kw_hr: "",
        noon_bad_weather_hrs: "",
        noon_bad_wether_dist: "",
        noon_sea_state: "",
        noon_sea_dir: "",
        noon_sea_height: "",
        noon_swell: "",
        noon_swell_dir: "",
        noon_swell_height: "",
        noon_wind_for: "",
        noon_wind_dir: "",
        noon_do_consumption_mt: "",
        noon_shaft_gen_power: "",
        noon_steaming_hr_at_econmical_speed: "",
        noon_aux_engine_rmp: "",
      },
      bunker: {
        noon_brob_info: "0.00",
        noon_brob_vlsfo: "0.00",
        noon_brob_ulsfo: "0.00",
        noon_brob_lsmgo: "0.00",
        noon_brob_mgo: "0.00",
        noon_me_propulsion_info: "",
        noon_mePropulsion_vlsfo: "",
        noon_me_propulsion_ulsfo: "",
        noon_me_propulsion_lsmgo: "",
        noon_me_propulsion_mgo: "",
        noon_boiler_info: "",
        noon_boiler_vlsfo: "",
        noon_boiler_ulsfo: "",
        noon_boiler_lsmgo: "",
        noon_boiler_mgo: "",
        noon_generator_info: "",
        noon_generator_vlsfo: "",
        noon_generator_ulsfo: "",
        noon_generator_lsmgo: "",
        noon_generator_mgo: "",
        noon_fuel_receoved_info: "",
        noon_fuel_receoved_vlsfo: "",
        noon_fuel_receoved_ulsfo: "",
        noon_fuel_receoved_lsmgo: "",
        noon_fuel_receoved_mgo: "",
        noon_fuel_debunker_info: "",
        noon_fuel_debunker_vlsfo: "",
        noon_fuel_debunker_ulsfo: "",
        noon_fuel_debunker_lsmgo: "",
        noon_fuel_debunker_mgo: "",
        noon_aux_exngine_info: "",
        noon_aux_exngine_vlsfo: "",
        noon_aux_exngine_ulsfo: "",
        noon_aux_exngine_lsmgo: "",
        noon_aux_exngine_mgo: "",
      },
      remark: {
        noon_remark: "",
        noon_captain: "",
        noon_ce: "",
      },
    },
  });

  const [imgUrl, setImgUrl] = useState('');
  const [ports, setPorts] = useState(null);
  const [isPortModal, setPortModal] = useState(false);
  const [key, setKey] = useState("");
  const [mailState, setMailState] = useState({
    showEmailModal: false,
    pdf: "",
    loading: false,
  })

  useEffect(() => {
    const params = new URLSearchParams(window.location.hash);
    const urlVoyageNumber = params.get("voyage_number");

    const getFormInfo = async () => {
      const response = await getAPICall(
        `${URL_WITH_VERSION}/voyage-manager/voyage-data?ae=${urlVoyageNumber}`
      );

      const data = await response.data;
      let obj = data[0];

      setCompanyDetails({ company_name: obj.full_name, company_logo: obj.logo, company_address: obj.address })
      setFormdata((prevState) => ({
        ...prevState,
        report_content: {
          ...prevState.report_content,
          mainInformation: {
            ...prevState.report_content.mainInformation,
            noon_vessel: obj.vessel_name,
            noon_imo: obj.imo_no,
            noon_voyage_no: obj.estimate_id,
          },
        },
      }));

      const portOptions = ports && ports.length > 0 && ports.map(({ port, port_id }) => ({
        label: port,
        value: port,
        key: port_id,
      }));
      setPorts(portOptions);
      fetchImage(obj)
    };

    if (props.byEdit == true) {
      getEditData();
    } else {
      getFormInfo();
    }
    getSeaState()

  return () => {
      if (imgUrl) {
          URL.revokeObjectURL(imgUrl);
      }
  };
  }, []);

  const fetchImage = async (obj) => {
    try {
        const imgUrl = await getBlobURl(obj.logo);
        setImgUrl(imgUrl);
    } catch (error) {
        console.error('Error fetching image:', error);
    }
};

  const getBlobURl = async (url) => {
    try{
        
        let StreamOFImage = await downloadAndGetStreambleReadObject(url)
        const reader = StreamOFImage.getReader();
        let totalBytes = 0;
        const chunks = [];
  
        while (true) {
            const { done, value } = await reader.read();
            if (done) {
                // console.log('Stream finished, total bytes:', totalBytes);
                break;
            }
            totalBytes += value.byteLength;
            // console.log('Received chunk:', value);
            chunks.push(value)
        }
  
      // Concatenate all chunks into a single Uint8Array
      const imageData = new Uint8Array(chunks.reduce((acc, chunk) => acc.concat(Array.from(chunk)), []));
  
      // Create a Blob from the binary data
      const blob = new Blob([imageData], { type: 'image/jpeg' });
  
      // Create an object URL from the Blob
      const imgUrl = URL.createObjectURL(blob);
      // console.log(imgUrl)
      let arr = document.getElementById("c-logo")
      arr.src =  imgUrl
      return imgUrl
  
    } catch (e) {
      console.error(e)
    }
  }
  
  async function downloadAndGetStreambleReadObject(url) {
    // console.log('urrrllll', url);
    let resonse = await fetch(url);
    // console.log(resonse);
    const reader = resonse.body.getReader();
    return new ReadableStream({
        start(controller) {
            function push() {
                reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;
                    }
                    controller.enqueue(value);
                    push();
                }).catch(error => {
                    console.error('Error reading stream:', error);
                    controller.error(error);
                });
            }
            push();
        }
    })
  }
  

  const getSeaState = async() => {
    try {
      const response = await getAPICall(`${URL_WITH_VERSION}/master/list?t=seafo&p=1&l=0`);
      const data = await response.data;
      setSeaState(data);
    } catch (err) {
      console.error(err);
    }
  }

  const modalCloseEvent = (data) => {
    if (key == "departure_port") {
      setFormdata((prev) => ({
        ...prev,
        report_content: {
          ...prev.report_content,
          portInformation: {
            ...prev.report_content.portInformation,
            noon_port: data.port.port_name,
            noon_port_id: data.port.PortID,
          },
        },
      }));
    }
    if (key === "arrival_port") {
      setFormdata((prev) => ({
        ...prev,
        report_content: {
          ...prev.report_content,
          portInformation: {
            ...prev.report_content.portInformation,
            noon_next_port: data.port.port_name,
            noon_next_port_id: data.port.PortID,
          },
        },
      }));
    }

    setPortModal(false);
  };

  const handleChange = (e, group) => {
    // console.log("group", group);
    const { name, value } = e.target;
    setFormdata((prevState) => ({
      ...prevState,
      report_content: {
        ...prevState.report_content,
        [group]: {
          ...prevState.report_content[group],
          [name]: value,
        },
      },
    }));
  };

  const handleSelect = (value, group, field) => {
    setFormdata((prevState) => ({
      ...prevState,
      report_content: {
        ...prevState.report_content,
        [group]: {
          ...prevState.report_content[group],
          [field]: value,
        },
      },
    }));
  };

  const handleSelectDate = (date, dateString, group, field) => {
    setFormdata((prevState) => ({
      ...prevState,
      report_content: {
        ...prevState.report_content,
        [group]: {
          ...prevState.report_content[group],
          [field]: dayjs(date, "YYYY-MM-DD HH:mm:ss"),
        },
      },
    }));
  };

  const getEditData = async (id) => {
    try {
      let response = await getAPICall(
        `${URL_WITH_VERSION}/voyage-manager/vessel/edit-noon-report?ae=${props.reportNo}`
      );
      let data = await response.data;
      fetchImage(data)
      setCompanyDetails({ company_name: data.full_name, company_logo: data.logo, company_address: data.address })
      setFormdata((prevState) => ({
        ...prevState,
        report_content: Object.assign({}, data.report_content),
        id: data.id,
        status: data.status,
      }));
    } catch (err) {
      console.error(err);
    }
  };

  const saveFormData = async (postData) => {
    try {
        let _url = "save";
        let _method = "post";

        if (props.byEdit) {
            _url = "update";
            _method = "put";
        }

        if (_url === "update") {
            postData.id = props.reportNo;
        }

        const suURL = `${URL_WITH_VERSION}/voyage-manager/vessel/${_url}-noon-report`;

        const { report_content } = postData;
        const { vesselDistance, portInformation } = report_content;

        const isMandatoryFieldsFilled =
            vesselDistance.noon_ordered_spd !== "" &&
            vesselDistance.noon_reported_spd !== "" &&
            vesselDistance.noon_passage !== "" &&
            portInformation.noon_dtg !== "";

        if (!isMandatoryFieldsFilled) {
            openNotificationWithIcon('info', "Please fill all the mandatory fields!");
            return;
        }

        const noon_lat = parseFloat(portInformation.noon_lat);
        const noon_lon = parseFloat(portInformation.noon_main_long);

        if (portInformation.noon_lat === "" || portInformation.noon_main_long === "") {
          openNotificationWithIcon('info', "Please fill Latitude and Longitude");
          return;
        }      

        if (noon_lat > 90 || noon_lat < -90){
            openNotificationWithIcon('info', 'Please Provide Correct Latitude');
        } else if (
            portInformation.noon_main_long !== "" &&
            (noon_lon > 180 || noon_lon < -180)
        ) {
            openNotificationWithIcon('info', 'Please Provide Correct Longitude');
        } else {
            postAPICall(suURL, postData, _method, (data) => {
                if (data && data.data) {
                    openNotificationWithIcon("success", data.message);
                } else {
                    openNotificationWithIcon("error", data.message);
                }
            });
        }
    } catch (e) {
        console.error('Error in API call', e);
    }
  };

  const printDocument = () => {
    const element = document.getElementById("divToPrint");
    const width = element.offsetWidth;
    const height = element.offsetHeight;

    html2canvas(element, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
      allowTaint: true,
      scale: 2,
      width: width,
      height: height,
      scrollY: height,
      scrollX: 0,
      windowWidth: document.body.scrollWidth,
      windowHeight: document.body.scrollHeight
    }).then(function (canvas) {
      let imgData = canvas.toDataURL("image/png");
      let imgWidth = 210;
      let pageHeight = 295;
      let imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      const pdf = new jsPDF('p', 'mm', 'a4');
      let position = 0;

      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("Noon_Report.pdf");
    }).catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    const draftData = localStorage.getItem('draftNoonReport');
    if (draftData) {
      // console.log("draft Data", draftData);
      const params = new URLSearchParams(window.location.hash);
      const urlVoyageNumber = params.get("voyage_number");
      const parsedDraftData = JSON.parse(draftData);
      if (parsedDraftData.report_content && parsedDraftData.report_content.mainInformation.noon_voyage_no === urlVoyageNumber) {
        setFormdata(parsedDraftData);
      }
    }
  }, []);

  const saveAsDraft = () => {
    localStorage.setItem('draftNoonReport', JSON.stringify(formdata));
    const draftData = localStorage.getItem('draftNoonReport');
    if(draftData){
      openNotificationWithIcon('success', 'Draft saved successfully');
    }
  };

  const sendEmailReportFunction = async () => {
    // setLoading(true);
    var quotes = document.getElementById("divToPrint");

    const canvas = await html2canvas(quotes, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
      allowTaint: true,
      scale: 5,
    });

    const canvasToPDF = new jsPDF({
      orientation: "p",
      unit: "mm",
      format: [2000, canvas.height],
    });
    const imageDataUrl = canvas.toDataURL("image/Jpeg");
    canvasToPDF.addImage(
      imageDataUrl,
      "JPEG",
      0,
      0,
      canvasToPDF.internal.pageSize.width,
      canvasToPDF.internal.pageSize.height
    );

    const blob = canvasToPDF.output("blob", { jpegQuality: 0.2 });
    setMailState((prevState) => ({
      ...prevState,
      loading: false,
      pdf: blob,
      showEmailModal: true,
    }));
  };

  const params = new URLSearchParams(window.location.hash);
  const vessel_name = params.get("vessel_name");
  const voyage_number = params.get("voyage_number");
  

  return (
    <>
      <div className="parentBox" id="divToPrint" ref={componentRef}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <img
            src={`/theoceannlogo.png`}
            width={150}
            alt="logo"
            style={{ marginLeft: "1rem" }}
          ></img>

          <div>
            <Button onClick={() => saveAsDraft()}>Save as Draft</Button>
            <Button type="primary" onClick={() => saveFormData(formdata)}>
              Send Report
            </Button>
            <Button type="primary" onClick={() => printDocument()}>
              Download <DownloadOutlined />
            </Button>
            <Button type="primary" onClick={() => sendEmailReportFunction()}>Send E-mail</Button>
            <ReactToPrint
              trigger={() => (
                <Button style={{ display: "" }} id="print-button">
                  Print <PrinterFilled />
                </Button>
              )}
              content={() => componentRef.current}
            />
          </div>
        </div>

        <div className="col-12 text-center">
          <div className="Company-Header-details">
            <img
              className="reportlogo rounded mb-3"
              src={imgUrl}
              alt="No img"
              id="c-logo"
            />

            <p className="title">
              {companyDetails.company_name
                ? companyDetails.company_name
                : ""}
            </p>
            <p className="address">
              {companyDetails.company_address
                ? companyDetails.company_address
                : ""}
            </p>
          </div>
        </div>
        <Row align="middle">
          <Col span={8}>
            <h3>Noon Report</h3>
          </Col>
          <Col span={16}>
          </Col>
        </Row>

        <div className="rpbox">
          <Row>
            <h4 className="mainHeading" >Main Information</h4>
          </Row>
          <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <Row gutter={[80]}>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={2}>
                  <Col span={8}>
                    <label htmlFor="field1">Vessel</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      value={
                        formdata.report_content.mainInformation.noon_vessel
                      }
                      name="noon_vessel"
                      id="noon_vessel"
                      onChange={(e) => handleChange(e, "mainInformation")}
                      disabled
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row>
                  <Col span={8}>
                    <label htmlFor="field2">IMO:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      name="noon_imo"
                      id="noon_imo"
                      value={formdata.report_content.mainInformation.noon_imo}
                      onChange={(e) => handleChange(e, "mainInformation")}
                      disabled
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row>
                  <Col span={8}>
                    <label htmlFor="field3">Call Sign:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      name="noon_call_sign"
                      id="noon_call_sign"
                      onChange={(e) => handleChange(e, "mainInformation")}
                      value={
                        formdata.report_content.mainInformation.noon_call_sign
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={80}>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={16}>
                  <Col span={8}>
                    <label htmlFor="field4">Time Zone:</label>
                  </Col>
                  <Col span={16}>
                    <Row gutter={1}>
                      <Col span={8}>
                        <Select
                          style={{ border: "1px solid #fcfcfc" }}
                          name="noon_time_zone"
                          id="noon_time_zone"
                          value={
                            formdata.report_content.mainInformation
                              .noon_time_zone
                          }
                          onChange={(value) => {
                            handleSelect(
                              value,
                              "mainInformation",
                              "noon_time_zone"
                            );
                          }}
                        // options={[
                        //   { label: "GMT", value: "GMT" },
                        //   { label: "Local", value: "Local" },
                        //   { label: "UTC", value: "UTC" },
                        // ]}
                        >
                          <Option value="GMT">GMT</Option>
                          <Option value="Local">Local</Option>
                          <Option value="UTC">UTC</Option>
                        </Select>
                      </Col>
                      <Col span={8}>
                        <Select
                          style={{ border: "1px solid #fcfcfc" }}
                          name="noon_time_zone_plus"
                          id="noon_time_zone_plus"
                          value={
                            formdata.report_content.mainInformation
                              .noon_time_zone_plus
                          }
                          onChange={(value) => {
                            handleSelect(
                              value,
                              "mainInformation",
                              "noon_time_zone_plus"
                            );
                          }}
                        // options={[
                        //   { label: "+", value: "+" },
                        //   { label: "-", value: "-" },
                        // ]}
                        >
                          <Option value="+">+</Option>
                          <Option value="-">-</Option>
                        </Select>
                      </Col>
                      <Col span={8}>
                        <Input
                          style={{ height: "100%" }}
                          onChange={(e) => handleChange(e, "mainInformation")}
                          name="noon_9h"
                          id="noon_9h"
                          value={
                            formdata.report_content.mainInformation.noon_9h
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8}>
                  <Col span={8}>
                    <label>Date</label>
                  </Col>
                  <Col span={16} >
                    <DatePicker
                      showTime
                      format="YYYY-MM-DD HH:mm:ss"
                      name="noon_dateee"
                      id="noon_dateee"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.mainInformation.noon_dateee
                          ? dayjs(
                            formdata.report_content.mainInformation
                              .noon_dateee,
                            "YYYY-MM-DD HH:mm:ss"
                          )
                          : undefined
                      }
                      onChange={(date, dateString) => {
                        handleSelectDate(
                          date,
                          dateString,
                          "mainInformation",
                          "noon_dateee"
                        );
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row>
                  <Col span={8}>
                    <label htmlFor="field4">Voyage No. :</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "mainInformation")}
                      type="text"
                      name="noon_voyage_no"
                      id="noon_voyage_no"
                      value={
                        formdata.report_content.mainInformation.noon_voyage_no
                      }
                      required
                      disabled
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>

        <div className="rpbox">
          <Row>
            <h4 className="mainHeading">
              Port Information
            </h4>
          </Row>

          <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <Row gutter={[80]}>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8}>
                  <Col span={8}>
                    <label htmlFor="input">Next Port:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onClick={() => {
                        setKey("arrival_port");
                        setPortModal(true);
                      }}
                      type="text"
                      name="noon_next_port"
                      id="noon_next_port"
                      className="form-control"
                      value={formdata.report_content.portInformation.noon_next_port}
                      onChange={(value) => {
                        handleChange(value, "portInformation");
                      }}
                      required
                      style={{ width: "100%" }}
                      placeholder="Arrival Port"
                    />
                    {isPortModal && (
                      <Modal
                        title="Select Port"
                        open={isPortModal}
                        width="80%"
                        onCancel={() => {
                          setPortModal(false);
                        }}
                        footer={null}
                      >
                        <PortSelection
                          fromPortID={null}
                          modalCloseEvent={(data) => {
                            modalCloseEvent(data);
                          }}
                        />
                      </Modal>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Row gutter={8}>
                  <Col span={8}>
                    <label>Next Port ETA:</label>
                  </Col>
                  <Col span={16}>
                    <DatePicker
                      showTime
                      format="YYYY-MM-DD HH:mm:ss"
                      name="noon_eta"
                      id="noon_eta"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.portInformation.noon_eta
                          ? dayjs(
                            formdata.report_content.portInformation.noon_eta,
                            "YYYY-MM-DD HH:mm:ss"
                          )
                          : undefined
                      }
                      onChange={(date, dateString) => {
                        handleSelectDate(
                          date,
                          dateString,
                          "portInformation",
                          "noon_eta"
                        );
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Row gutter={8}>
                  <Col span={8}>
                    <label>Departure Port:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onClick={() => {
                        setKey("departure_port");
                        setPortModal(true);
                      }}
                      type="text"
                      name="noon_port"
                      id="noon_port"
                      className="form-control"
                      value={
                        formdata.report_content.portInformation.noon_port
                      }
                      onChange={(value) => {
                        handleChange(value, "portInformation");
                      }}
                      required
                      style={{ width: "100%" }}
                      placeholder="Departure Port"
                    />
                    {isPortModal && (
                      <Modal
                        title="Select Port"
                        visible={isPortModal}
                        width="80%"
                        onCancel={() => {
                          setPortModal(false);
                        }}
                        footer={null}
                      >
                        <PortSelection
                          fromPortID={null}
                          modalCloseEvent={(data) => {
                            modalCloseEvent(data);
                          }}
                        />
                      </Modal>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={[80]}>
              <Col span={8}>
                <Row gutter={8}>
                  <Col span={8}>
                    <label htmlFor="field3"> Long. : <span style={{color: 'red', fontWeight: '600'}}>*</span></label>
                  </Col>
                  <Col span={16}>
                    <Row gutter={8}>
                      <Col span={12}>
                        <Input style={{ height: "100%" }}
                          onChange={(e) => handleChange(e, "portInformation")}
                          type="text"
                          name="noon_main_long"
                          id="noon_main_long"
                          value={
                            formdata.report_content.portInformation.noon_main_long
                          }
                          placeholder="DDMMSS"
                          required
                        />
                      </Col>
                      <Col span={12}>
                        <Select
                          defaultValue="option1"
                          name="noon_main_long_dir"
                          id="noon_main_long_dir"
                          style={{ width: "100%" }}
                          value={
                            formdata.report_content.portInformation.noon_main_long_dir
                          }
                          onChange={(value) => {
                            handleSelect(
                              value,
                              "portInformation",
                              "noon_main_long_dir"
                            );
                          }}
                        >
                          <Option value="E">E</Option>
                          <Option value="W">W</Option>
                        </Select>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Row gutter={8}>
                  <Col span={8}>
                    <label>DTG: <span style={{color: 'red', fontWeight: '700'}}>*</span></label>
                  </Col>
                  <Col span={16}>
                    <Input
                      type="text"
                      name="noon_dtg"
                      id="noon_dtg"
                      placeholder="Miles"
                      onChange={(e) => {
                        handleChange(e, "portInformation");
                      }}
                      value={formdata.report_content.portInformation.noon_dtg}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Row gutter={8}>
                  <Col span={8}>
                    <label>Hours to Go:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "portInformation")}
                      type="text"
                      name="noon_hours_to_go"
                      id="noon_hours_to_go"
                      placeholder="Miles"
                      value={
                        formdata.report_content.portInformation.noon_hours_to_go
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* <Col span={0} /> */}
            <Row gutter={[80]}>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={2} align="middle">
                  <Col span={8}>
                    <label htmlFor="field2"> Lat. : <span style={{color: 'red', fontWeight: '600'}}>*</span></label>
                  </Col>
                  <Col span={16}>
                    <Row gutter={8}>
                      <Col span={12}>
                        <Input
                          style={{ height: "100%" }}
                          onChange={(e) => handleChange(e, "portInformation")}
                          type="text"
                          name="noon_lat"
                          id="noon_main_Lat"
                          placeholder="DDMMSS"
                          value={formdata.report_content.portInformation.noon_lat}
                        />
                      </Col>
                      <Col span={12}>
                        <Select
                          defaultValue="option1"
                          name="noon_lat_dir"
                          id="noon_lat_dir"
                          value={formdata.report_content.portInformation.noon_lat_dir}
                          style={{ width: "100%" }}
                          onChange={(value) => {
                            handleSelect(value, "portInformation", "noon_lat_dir");
                          }}
                        >
                          <Option value="N">N</Option>
                          <Option value="S">S</Option>
                        </Select>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

        </div>

        <div className="rpbox" >
          <Row>
            <h4 className="mainHeading" >
              Cargo Information
            </h4>
          </Row>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <Row gutter={[80]}>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label>Ttl Cargo Onboard:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "cargoInformation")}
                      type="number"
                      name="noon_ttl_cargo_onboard"
                      id="noon_ttl_cargo_onboard"
                      value={
                        formdata.report_content.cargoInformation
                          .noon_ttl_cargo_onboard
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label>Displacement Mt:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "cargoInformation")}
                      type="number"
                      name="noon_displacement_mt"
                      id="noon_displacement_mt"
                      value={
                        formdata.report_content.cargoInformation
                          .noon_displacement_mt
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label>Deck Cargo Mt:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "cargoInformation")}
                      type="number"
                      name="noon_deck_cargo_mt"
                      id="noon_deck_cargo_mt"
                      value={
                        formdata.report_content.cargoInformation
                          .noon_deck_cargo_mt
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={[80]}>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label>FW consumed:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "cargoInformation")}
                      type="number"
                      name="noon_fw_consumed"
                      id="noon_fw_consumed"
                      value={
                        formdata.report_content.cargoInformation
                          .noon_fw_consumed
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label>FW ROB:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "cargoInformation")}
                      type="number"
                      name="noon_fw_rob"
                      id="noon_fw_rob"
                      value={
                        formdata.report_content.cargoInformation.noon_fw_rob
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label>Slop ROB:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "cargoInformation")}
                      type="number"
                      name="noon_slop_rob"
                      id="noon_slop_rob"
                      value={
                        formdata.report_content.cargoInformation.noon_slop_rob
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={[80]}>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label>Hold</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "cargoInformation")}
                      type="number"
                      name="noon_hold_cargo_mt"
                      id="noon_hold_cargo_mt"
                      value={
                        formdata.report_content.cargoInformation
                          .noon_hold_cargo_mt
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label>SLOP Produced:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "cargoInformation")}
                      type="number"
                      name="noon_slop_produced"
                      id="noon_slop_produced"
                      value={
                        formdata.report_content.cargoInformation
                          .noon_slop_produced
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label>Ballast Water:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "cargoInformation")}
                      type="number"
                      name="noon_ballast_water"
                      id="noon_ballast_water"
                      value={
                        formdata.report_content.cargoInformation
                          .noon_ballast_water
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={[80]}>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label>Distilled Cons. :</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "cargoInformation")}
                      type="number"
                      name="noon_distilled_cons"
                      id="noon_distilled_cons"
                      value={
                        formdata.report_content.cargoInformation
                          .noon_distilled_cons
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label>Distilled ROB:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "cargoInformation")}
                      type="number"
                      name="noon_distilled_rob"
                      id="noon_distilled_rob"
                      value={
                        formdata.report_content.cargoInformation
                          .noon_distilled_rob
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label>Load Condition:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "cargoInformation")}
                      type="number"
                      name="load_condition"
                      id="load_condition"
                      value={
                        formdata.report_content.cargoInformation.load_condition
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>

        <div className="rpbox" >
          <Row>
            <h4 className="mainHeading">
              Vessel & Distance
            </h4>
          </Row>
          <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <Row gutter={[80]}>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8} >
                    <label style={{ marginRight: "8px" }}>Passage: <span style={{color: 'red', fontWeight: '700'}}>*</span> </label>
                  </Col>
                  <Col span={16}>
                    <Select
                      name="noon_passage"
                      id="noon_passage"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance.noon_passage
                      }
                      onChange={(value) => {
                        handleSelect(value, "vesselDistance", "noon_passage");
                      }}
                    >
                      <Option value="Laden">Laden</Option>
                      <Option value="Ballast">Ballast</Option>
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Aver RPM:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(value) =>
                        handleChange(value, "vesselDistance")
                      }
                      type="number"
                      name="noon_aver_rpm"
                      id="noon_aver_rpm"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance.noon_aver_rpm
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Gen 1 Hr:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(value) =>
                        handleChange(value, "vesselDistance")
                      }
                      type="number"
                      name="noon_gen_one_hr"
                      id="noon_gen_one_hr"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance.noon_gen_one_hr
                      }
                    />
                  </Col>
                </Row>
              </Col>

            </Row>

            <Row gutter={[80]}>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>
                      Bad Weather hrs:
                    </label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(value) =>
                        handleChange(value, "vesselDistance")
                      }
                      type="number"
                      name="noon_bad_weather_hrs"
                      id="noon_bad_weather_hrs"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance
                          .noon_bad_weather_hrs
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Ordered SPD : <span style={{color: 'red', fontWeight: '700'}}>*</span> </label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_ordered_spd"
                      id="noon_ordered_spd"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance.noon_ordered_spd
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Ave BHP:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_ave_bhp"
                      id="noon_ave_bhp"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance.noon_ave_bhp
                      }
                    />
                  </Col>
                </Row>
              </Col>

            </Row>

            <Row gutter={[80]}>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Gen 2 Hr:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      type="number"
                      name="noon_gen_two_hr"
                      id="noon_gen_two_hr"
                      style={{ width: "100%" }}
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      value={
                        formdata.report_content.vesselDistance.noon_gen_two_hr
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>
                      Bad weathr Dist:
                    </label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(value) =>
                        handleChange(value, "vesselDistance")
                      }
                      type="number"
                      name="noon_bad_wether_dist"
                      id="noon_bad_wether_dist"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance
                          .noon_bad_wether_dist
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Reported spd: <span style={{color: 'red', fontWeight: '700'}}>*</span> </label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_reported_spd"
                      id="noon_reported_spd"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance.noon_reported_spd
                      }
                    />
                  </Col>
                </Row>
              </Col>

            </Row>

            <Row gutter={[80]}>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>FWD dft:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_fwd_dft"
                      id="noon_fwd_dft"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance.noon_fwd_dft
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Gen 3 Hr:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(value) =>
                        handleChange(value, "vesselDistance")
                      }
                      type="number"
                      name="noon_gen_three_hr"
                      id="noon_gen_three_hr"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance.noon_gen_three_hr
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Sea state:</label>
                  </Col>
                  <Col span={16}>
                    <Select
                      name="noon_sea_state"
                      id="noon_sea-state"
                      value={
                        formdata.report_content.vesselDistance.noon_sea_state
                      }
                      style={{ width: "100%" }}
                      onChange={(value) => {
                        handleSelect(value, "vesselDistance", "noon_sea_state");
                      }}
                    >
                      {seaState.length > 0 && seaState.map((optionValue) => (
                      <Option
                        key={optionValue.id}
                        value={optionValue.description.toString()}
                      >
                        {optionValue.description}
                      </Option>
                    ))}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[80]}>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>
                      Observe distance:
                    </label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(value) =>
                        handleChange(value, "vesselDistance")
                      }
                      type="number"
                      name="noon_observe_dist"
                      id="noon_observe_dist"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance.noon_observe_dist
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Aft drft:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_aft_drft"
                      id="noon_aft_drft"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance.noon_aft_drft
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Gen 4 Hr:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(value) =>
                        handleChange(value, "vesselDistance")
                      }
                      type="number"
                      name="noon_gen_four_hr"
                      id="noon_gen_four_hr"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance.noon_gen_four_hr
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[80]}>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Sea Direction:</label>
                  </Col>
                  <Col span={16}>
                    <Select
                      name="noon_sea_dir"
                      id="noon_sea_dir"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance.noon_sea_dir
                      }
                      onChange={(value) => {
                        handleSelect(value, "vesselDistance", "noon_sea_dir");
                      }}
                    >
                      <Option value="North">North</Option>
                      <Option value="South">South</Option>
                      <Option value="East">East</Option>
                      <Option value="West">West</Option>
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Engine dist. :</label>{" "}
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_engine_dist"
                      id="noon_engine_dist"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance.noon_engine_dist
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Mid draft:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_mid_draft"
                      id="noon_mid_draft"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance.noon_mid_draft
                      }
                    />
                  </Col>
                </Row>
              </Col>

            </Row>

            <Row gutter={[80]}>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>M/E HR:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_me_hr"
                      id="noon_me_hr"
                      style={{ width: "100%" }}
                      value={formdata.report_content.vesselDistance.noon_me_hr}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Sea height:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_sea_height"
                      id="noon_sea_height"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance.noon_sea_height
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>
                      Main Eng Rev. :
                    </label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_main_eng_rev"
                      id="noon_main_eng_rev"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance.noon_main_eng_rev
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>


            <Row gutter={[80]}>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Steaming Hr:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_steaming_hr"
                      id="noon_steaming_hr"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance.noon_steaming_hr
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Gen 1 KWHR:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_gen_one_kw_hr"
                      id="noon_gen_one_kw_hr"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance
                          .noon_gen_one_kw_hr
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Swell: </label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_swell"
                      id="noon_swell"
                      style={{ width: "100%" }}
                      value={formdata.report_content.vesselDistance.noon_swell}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[80]}>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Slip% :</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_slip_perc"
                      id="noon_slip_perc"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance.noon_slip_perc
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Air press:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_air_press"
                      id="noon_air_press"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance.noon_air_press
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Gen 2 KWHR:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_gen_two_kw_hr"
                      id="noon_gen_two_kw_hr"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance
                          .noon_gen_two_kw_hr
                      }
                    />
                  </Col>
                </Row>
              </Col>

            </Row>

            <Row gutter={[80]}>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>
                      Swell Direction:
                    </label>
                  </Col>
                  <Col span={16}>
                    <Select

                      name="noon_swell_dir"
                      id="noon_swell_direction"
                      value={
                        formdata.report_content.vesselDistance.noon_swell_dir
                      }
                      style={{ width: "100%" }}
                      onChange={(value) => {
                        handleSelect(value, "vesselDistance", "noon_swell_dir");
                      }}
                    >
                      <Option value="North">North</Option>
                      <Option value="South">South</Option>
                      <Option value="East">East</Option>
                      <Option value="West">West</Option>
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Salinity:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_salinity"
                      id="noon_salinity"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance.noon_salinity
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Air Tempt. :</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_air_tempt"
                      id="noon_air_tempt"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance.noon_air_tempt
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[80]}>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Gen 3 KWHR:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_gen_three_kw_hr"
                      id="noon_gen_three_kw_hr"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance
                          .noon_gen_three_kw_hr
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Swell Height:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_swell_height"
                      id="noon_swell_height"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance.noon_swell_height
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Boiler hrs:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_boiler_hrs"
                      id="noon_boiler_hrs"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance.noon_boiler_hrs
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[80]}>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>
                      Main Eng Rev. :
                    </label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_main_eng_rev"
                      id="noon_main_eng_rev"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance.noon_main_eng_rev
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Gen 4 KWHR:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_gen_four_kw_hr"
                      id="noon_gen_four_kw_hr"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance
                          .noon_gen_four_kw_hr
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Wind For:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_wind_for"
                      id="noon_wind_for"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance.noon_wind_for
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[80]}>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>
                      Incinerator Hrs:
                    </label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_incinerator_hrs"
                      id="noon_incinerator_hrs"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance
                          .noon_incinerator_hrs
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>M/E KWHR:</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_me_kw_hr"
                      id="noon_me_kw_hr"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance.noon_me_kw_hr
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>
                      Wind direction:
                    </label>
                  </Col>
                  <Col span={16}>
                    <Select
                      value={
                        formdata.report_content.vesselDistance.noon_wind_dir
                      }
                      style={{ width: "100%" }}
                      onChange={(value) => {
                        handleSelect(value, "vesselDistance", "noon_wind_dir");
                      }}
                    >
                      <Option value="North">North</Option>
                      <Option value="South">South</Option>
                      <Option value="East">East</Option>
                      <Option value="West">West</Option>
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[80]}>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Average Speed</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_average_speed"
                      id="noon_average_speed"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance
                          .noon_average_speed
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>
                      Miles steamed since Last Noon Report
                    </label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_miles_steamed_since_last_noon_report"
                      id="noon_miles_steamed_since_last_noon_report"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance
                          .noon_miles_steamed_since_last_noon_report
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Density</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_density"
                      id="noon_density"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance.noon_density
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[80]}>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>
                      Total Steaming Hrs
                    </label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_total_steaming_hr"
                      id="noon_total_steaming_hr"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance
                          .noon_total_steaming_hr
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>
                      Engine oil Consumption
                    </label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_engine_oil_consumption"
                      id="noon_engine_oil_consumption"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance
                          .noon_engine_oil_consumption
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Incirator Hrs</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_incirator_hrs"
                      id="noon_incirator_hrs"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance
                          .noon_incirator_hrs
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[80]}>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>
                      Steaming Hr At Slow Speed
                    </label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_steaming_hr_at_slow_speed"
                      id="noon_steaming_hr_at_slow_speed"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance
                          .noon_steaming_hr_at_slow_speed
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>
                      Sea Surface Temp
                    </label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_sea_surface_temp"
                      id="noon_sea_surface_temp"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance
                          .noon_sea_surface_temp
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>
                      Total Miles Covered
                    </label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_total_miles_covered"
                      id="noon_total_miles_covered"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance
                          .noon_total_miles_covered
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[80]}>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>
                      M/E Exhaust Gas Temp
                    </label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_me_exhaust_gas_temp"
                      id="noon_me_exhaust_gas_temp"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance
                          .noon_me_exhaust_gas_temp
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>
                      Incinertor Consumption
                    </label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_incinertor_consumption"
                      id="noon_incinertor_consumption"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance
                          .noon_incinertor_consumption
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>
                      A/E Exhaust Gas Temp
                    </label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_ae_exhaust_gas_temp"
                      id="noon_ae_exhaust_gas_temp"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance
                          .noon_ae_exhaust_gas_temp
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>


            <Row gutter={[80]}>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>
                      Shaft Gen Running Hrs
                    </label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_shaft_gen_running_hr"
                      id="noon_shaft_gen_running_hr"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance
                          .noon_shaft_gen_running_hr
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}  >
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>
                      Steaming Hr At cp Speed
                    </label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_steaming_hr_at_cp_speed"
                      id="noon_steaming_hr_at_cp_speed"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance
                          .noon_steaming_hr_at_cp_speed
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>
                      Main Engine Rpm
                    </label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_main_engine_rpm"
                      id="noon_main_engine_rpm"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance
                          .noon_main_engine_rpm
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>


            <Row gutter={[80]}>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>
                      D.O Consumption
                    </label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_do_consumption_mt"
                      id="noon_do_consumption_mt"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance
                          .noon_do_consumption_mt
                      }
                    />
                  </Col>
                </Row>
              </Col>

              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>
                      Shaft Gen Power
                    </label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_shaft_gen_power"
                      id="noon_shaft_gen_power"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance
                          .noon_shaft_gen_power
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>
                      Steaming Hr At eco Speed
                    </label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_steaming_hr_at_econmical_speed"
                      id="noon_steaming_hr_at_econmical_speed"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance
                          .noon_steaming_hr_at_econmical_speed
                      }
                    />
                  </Col>
                </Row>
              </Col>

            </Row>


            <Row gutter={[80]}>

              <Col span={8} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                <Row gutter={8} align="middle">
                  <Col span={8}>
                    <label style={{ marginRight: "8px" }}>Aux Engine Rpm</label>
                  </Col>
                  <Col span={16}>
                    <Input
                      onChange={(e) => handleChange(e, "vesselDistance")}
                      type="number"
                      name="noon_aux_engine_rmp"
                      id="noon_aux_engine_rmp"
                      style={{ width: "100%" }}
                      value={
                        formdata.report_content.vesselDistance
                          .noon_aux_engine_rmp
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>

        <div className="rpbox">
          <h4 className="mainHeading" >Bunker</h4>
          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr className="trrow">
                      <th>Type (MT)</th>
                      <th>IFO</th>
                      <th>VLSFO</th>
                      <th>ULSFO</th>
                      <th>LSMGO</th>
                      <th>MGO</th>
                    </tr>
                  </thead>
                  <tbody>




                    <tr className="tableRow">
                      <td>BROB: <span style={{color: 'red', fontWeight: '600'}}>*</span></td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_brob_info"
                          id="noon_brob_info"
                          className="form-control"


                          value={formdata.report_content.bunker.noon_brob_info}
                        />
                      </td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_brob_vlsfo"
                          id="noon_brob_vlsfo"
                          className="form-control"

                          value={formdata.report_content.bunker.noon_brob_vlsfo}
                        />
                      </td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_brob_ulsfo"
                          id="noon_brob_ulsfo"
                          className="form-control"

                          value={formdata.report_content.bunker.noon_brob_ulsfo}
                        />
                      </td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_brob_lsmgo"
                          id="noon_brob_lsmgo"
                          className="form-control"

                          value={formdata.report_content.bunker.noon_brob_lsmgo}
                        />
                      </td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_brob_mgo"
                          id="noon_brob_mgo"
                          className="form-control"

                          value={formdata.report_content.bunker.noon_brob_mgo}
                        />
                      </td>
                    </tr>

                    <tr className="tableRow">
                      <td>M/E Propulsion:</td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_me_propulsion_info"
                          id="noon_me_propulsion_info"
                          className="form-control"
                          value={
                            formdata.report_content.bunker
                              .noon_me_propulsion_info
                          }
                        />
                      </td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_mePropulsion_vlsfo"
                          id="noon_mePropulsion_vlsfo"
                          className="form-control"

                          value={
                            formdata.report_content.bunker
                              .noon_mePropulsion_vlsfo
                          }
                        />
                      </td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_me_propulsion_ulsfo"
                          id="noon_me_propulsion_ulsfo"
                          className="form-control"

                          value={
                            formdata.report_content.bunker
                              .noon_me_propulsion_ulsfo
                          }
                        />
                      </td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_me_propulsion_lsmgo"
                          id="noon_me_propulsion_lsmgo"
                          className="form-control"

                          value={
                            formdata.report_content.bunker
                              .noon_me_propulsion_lsmgo
                          }
                        />
                      </td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_me_propulsion_mgo"
                          id="noon_me_propulsion_mgo"
                          className="form-control"

                          value={
                            formdata.report_content.bunker
                              .noon_me_propulsion_mgo
                          }
                        />
                      </td>
                    </tr>

                    <tr className="tableRow">
                      <td>Boiler:</td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_boiler_info"
                          id="noon_boiler_info"
                          className="form-control"

                          value={
                            formdata.report_content.bunker.noon_boiler_info
                          }
                        />
                      </td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_boiler_vlsfo"
                          id="noon_boiler_vlsfo"
                          className="form-control"

                          value={
                            formdata.report_content.bunker.noon_boiler_vlsfo
                          }
                        />
                      </td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_boiler_ulsfo"
                          id="noon_boiler_ulsfo"
                          className="form-control"
                          value={
                            formdata.report_content.bunker.noon_boiler_ulsfo
                          }
                        />
                      </td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_boiler_lsmgo"
                          id="noon_manoever-lsmgo"
                          className="form-control"
                          value={
                            formdata.report_content.bunker.noon_boiler_lsmgo
                          }
                        />
                      </td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_boiler_mgo"
                          id="noon_boiler_mgo"
                          className="form-control"
                          value={formdata.report_content.bunker.noon_boiler_mgo}
                        />
                      </td>
                    </tr>

                    <tr className="tableRow">
                      <td>Generator:</td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_generator_info"
                          id="noon_generator_info"
                          className="form-control"
                          value={
                            formdata.report_content.bunker.noon_generator_info
                          }
                        />
                      </td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_generator_vlsfo"
                          id="noon_generator_vlsfo"
                          className="form-control"
                          value={
                            formdata.report_content.bunker.noon_generator_vlsfo
                          }
                        />
                      </td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_generator_ulsfo"
                          id="noon_generator_ulsfo"
                          className="form-control"
                          value={
                            formdata.report_content.bunker.noon_generator_ulsfo
                          }
                        />
                      </td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_generator_lsmgo"
                          id="noon_generator_lsmgo"
                          className="form-control"
                          value={
                            formdata.report_content.bunker.noon_generator_lsmgo
                          }
                        />
                      </td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_generator_mgo"
                          id="noon_generator_mgo"
                          className="form-control"
                          value={
                            formdata.report_content.bunker.noon_generator_mgo
                          }
                        />
                      </td>
                    </tr>

                    <tr className="tableRow">
                      <td>Fuel Receoved:</td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_fuel_receoved_info"
                          id="noon_fuel_receoved_info"
                          className="form-control"
                        />
                      </td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_fuel_receoved_vlsfo"
                          id="noon_fuel_receoved_vlsfo"
                          className="form-control"
                          value={
                            formdata.report_content.bunker
                              .noon_fuel_receoved_vlsfo
                          }
                        />
                      </td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_fuel_receoved_ulsfo"
                          id="noon_fuel_receoved_ulsfo"
                          className="form-control"
                          value={
                            formdata.report_content.bunker
                              .noon_fuel_receoved_ulsfo
                          }
                        />
                      </td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_fuel_receoved_lsmgo"
                          id="noon_fuel_receoved_lsmgo"
                          className="form-control"
                          value={
                            formdata.report_content.bunker
                              .noon_fuel_receoved_lsmgo
                          }
                        />
                      </td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_fuel_receoved_mgo"
                          id="noon_fuel_receoved_mgo"
                          className="form-control"
                          value={
                            formdata.report_content.bunker
                              .noon_fuel_receoved_mgo
                          }
                        />
                      </td>
                    </tr>

                    <tr className="tableRow">
                      <td>Fuel Debunker:</td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_fuel_debunker_info"
                          id="noon_fuel_debunker_info"
                          className="form-control"
                          value={
                            formdata.report_content.bunker
                              .noon_fuel_debunker_info
                          }
                        />
                      </td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_fuel_debunker_vlsfo"
                          id="noon_fuel_debunker_vlsfo"
                          className="form-control"
                          value={
                            formdata.report_content.bunker
                              .noon_fuel_debunker_vlsfo
                          }
                        />
                      </td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_fuel_debunker_ulsfo"
                          id="noon_fuel_debunker_ulsfo"
                          className="form-control"
                          value={
                            formdata.report_content.bunker
                              .noon_fuel_debunker_ulsfo
                          }
                        />
                      </td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_fuel_debunker_lsmgo"
                          id="noon_fuel_debunker_lsmgo"
                          className="form-control"
                          value={
                            formdata.report_content.bunker
                              .noon_fuel_debunker_lsmgo
                          }
                        />
                      </td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_fuel_debunker_mgo"
                          id="noon_fuel_debunker_mgo"
                          className="form-control"
                          value={
                            formdata.report_content.bunker
                              .noon_fuel_debunker_mgo
                          }
                        />
                      </td>
                    </tr>

                    <tr className="tableRow">
                      <td>Aux Exngine:</td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_aux_exngine_info"
                          id="noon_aux_exngine_info"
                          className="form-control"
                          value={
                            formdata.report_content.bunker.noon_aux_exngine_info
                          }
                        />
                      </td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_aux_exngine_vlsfo"
                          id="noon_aux_exngine_vlsfo"
                          className="form-control"
                          value={
                            formdata.report_content.bunker
                              .noon_aux_exngine_vlsfo
                          }
                        />
                      </td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_aux_exngine_ulsfo"
                          id="noon_aux_exngine_ulsfo"
                          className="form-control"
                          value={
                            formdata.report_content.bunker
                              .noon_aux_exngine_ulsfo
                          }
                        />
                      </td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_aux_exngine_lsmgo"
                          id="noon_aux_exngine_lsmgo"
                          className="form-control"
                          value={
                            formdata.report_content.bunker
                              .noon_aux_exngine_lsmgo
                          }
                        />
                      </td>
                      <td>
                        <Input
                          onChange={(e) => handleChange(e, "bunker")}
                          type="number"
                          name="noon_aux_exngine_mgo"
                          id="noon_aux_exngine_mgo"
                          className="form-control"
                          value={
                            formdata.report_content.bunker.noon_aux_exngine_mgo
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="rpbox" style={{ gap: "2rem" }}

        >
          <Row gutter={[24, 24]}>


            <Col span={2}><label >Remark:</label></Col>
            <Col span={22}>
              <Input
                onChange={(e) => handleChange(e, "remark")}
                className="form-control"
                name="noon_remark"
                id="noon_remark"
                type="text"
                value={formdata.report_content.remark.noon_remark}
              />
            </Col>


          </Row>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Row gutter={8}>
                <Col span={4}><label>Captain:</label></Col>
                <Col span={20}>
                  <Input
                    onChange={(e) => handleChange(e, "remark")}
                    type="text"
                    name="noon_captain"
                    id="noon_captain"
                    className="form-control"
                    value={formdata.report_content.remark.noon_captain}
                  />
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <Row gutter={8}>

                <Col span={4}><label>C/E:</label></Col>

                <Col span={20}>
                  <Input
                    onChange={(e) => handleChange(e, "remark")}
                    type="text"
                    name="noon_ce"
                    id="noon_ce"
                    className="form-control"
                    value={formdata.report_content.remark.noon_ce}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        {
          mailState.showEmailModal && 
          <Modal
            onCancel={() => setMailState((prev) => ({
              ...prev,
              showEmailModal: false
            }))}
            visible={mailState.showEmailModal}
            footer={false}
            title="Share this Noon Report via E-mail."
            
          >
            <Email 
              handleClose={
                () => {
                  setMailState((prevState) => ({
                    showEmailModal: !prevState.showEmailModal,
                  }));
                }
              }
              attachmentFile={mailState.pdf}
              pdfname={`Noon Report for ${vessel_name}`}
              title={`Noon Report || ${vessel_name} || (${voyage_number}) || ${companyDetails.company_name}`}
              hideAtt={true}
            />
          </Modal>
        }
      </div>
    </>
  );
};

export default NoonReport;
